import React from "react"

export const ThursdayLogo = ({width, height}) => (
  <svg
    width={width || "672"}
    height={height || "146"}
    viewBox="0 0 672 146"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.8114 50.3406H54.9314V31.1406H37.8114V11.9406H13.6514V31.1406H0.211426V50.3406H13.6514V66.9806V82.5006C13.6514 103.141 26.1314 113.061 42.4514 113.061C46.4514 113.061 50.2914 112.581 54.2914 111.781L54.1314 91.3006C52.5314 91.6206 50.6114 91.7806 48.0514 91.7806C41.8114 91.7806 37.8114 87.9406 37.8114 80.7406V66.9806V50.3406Z"
      fill="#EC5B5E"
    />
    <path
      d="M114.38 29.5406C103.66 29.5406 95.3402 35.3006 91.3402 44.7406V0.26062H66.8602V112.261H91.3402V67.4606C91.3402 55.9406 96.7802 49.2206 105.9 49.2206C115.02 49.2206 120.3 55.4606 120.3 66.1806V112.261H144.78V62.5006C144.78 42.6606 132.62 29.5406 114.38 29.5406Z"
      fill="#EC5B5E"
    />
    <path
      d="M209.775 31.1406V75.9406C209.775 87.6206 204.495 94.1806 195.695 94.1806C187.375 94.1806 182.415 87.9406 182.415 77.3806V31.1406H157.935V80.9006C157.935 100.741 169.935 114.021 187.535 114.021C197.775 114.021 205.455 110.021 210.095 102.821L209.775 112.261H234.255V31.1406H209.775Z"
      fill="#EC5B5E"
    />
    <path
      d="M276.728 114.981L298.488 105.701L287.928 80.9006C279.768 62.6606 284.728 51.9406 298.648 51.9406C301.848 51.9406 303.608 52.1006 306.648 52.4206V30.3406C304.248 29.8606 301.528 29.5406 298.648 29.5406C284.728 29.5406 276.248 39.6206 276.888 55.3006H276.568L265.848 30.5006L244.088 39.6206L276.728 114.981Z"
      fill="#EC5B5E"
    />
    <path
      d="M352.16 115.301C372.8 115.301 387.52 104.101 387.52 87.9406C387.52 74.1806 377.28 66.0206 358.4 62.0206L350.56 60.2606C345.6 59.1406 343.2 56.7406 343.2 53.2206C343.2 48.5806 347.68 45.2206 353.76 45.2206C361.12 45.2206 367.36 50.0206 367.68 57.0606L389.76 56.5806C389.28 39.7806 374.24 28.1006 353.76 28.1006C333.92 28.1006 319.68 39.6206 319.68 56.1006C319.68 70.0206 327.84 77.8606 345.28 81.3806L353.12 83.1406C360 84.7406 363.68 86.3406 363.68 90.8206C363.68 95.3006 359.04 98.1806 352.16 98.1806C343.52 98.1806 337.92 93.5406 337.76 86.3406L315.52 86.9806C315.68 103.621 330.72 115.301 352.16 115.301Z"
      fill="#EC5B5E"
    />
    <path
      d="M460.938 0.26062V40.2606C455.178 33.3806 447.178 29.5406 437.258 29.5406C414.218 29.5406 398.218 46.8206 398.218 71.7806C398.218 96.7406 414.218 114.021 437.258 114.021C447.178 114.021 455.178 110.341 460.938 103.461V112.261H485.418V93.7006V0.26062H460.938ZM442.698 93.8606C431.018 93.8606 423.018 84.9006 423.018 71.7806C423.018 58.6606 431.178 49.5406 442.698 49.5406C454.218 49.5406 462.218 58.6606 462.218 71.7806C462.218 84.9006 454.218 93.8606 442.698 93.8606Z"
      fill="#EC5B5E"
    />
    <path
      d="M524.141 113.221C536.781 113.221 545.261 107.141 548.461 97.5406H548.941C548.941 104.581 549.421 109.541 550.221 112.261H573.901C572.781 106.021 572.301 96.1006 572.301 87.4606V62.5006C572.301 42.6606 556.941 28.7406 534.861 28.7406C513.901 28.7406 499.021 41.7006 498.221 60.4206L521.421 60.9006C521.741 53.5406 527.181 48.5806 534.861 48.5806C542.701 48.5806 547.981 53.7006 547.981 61.2206V64.2606L534.381 65.5406C512.301 67.4606 499.021 77.0606 499.021 91.6206C499.021 105.061 508.621 113.221 524.141 113.221ZM533.581 97.8606C527.341 97.8606 523.341 94.6606 523.341 89.5406C523.341 83.1406 528.621 79.1406 537.741 78.3406L548.301 77.2206V80.4206C548.301 90.1806 543.181 97.8606 533.581 97.8606Z"
      fill="#EC5B5E"
    />
    <path
      d="M598.22 145.061C616.14 145.061 629.9 134.021 638.38 112.741L671.66 31.1406H646.38L627.18 83.3006L606.22 31.1406H580.3L613.9 110.821L612.62 113.701C608.46 122.341 602.06 123.781 594.06 123.781C591.18 123.781 588.46 123.461 587.02 122.981V143.781C590.22 144.741 594.06 145.061 598.22 145.061Z"
      fill="#EC5B5E"
    />
  </svg>
)
